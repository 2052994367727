@import "@aws-amplify/ui-react/styles.css";

.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

div[data-amplify-authenticator] {
  margin: auto;
}