.udgrade-custom-font-11 {
    font-size: 11px;
}
.usage__table {
    max-width: 1000px;
}

/* .upgrade_container,
.upgrade_table {
    width: 90%;
} */