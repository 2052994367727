.plan_container {
    scroll-behavior: smooth;
}

.plan_grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.plan_grid-item-container {
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: repeat(6, 1fr);
    justify-items: center;
}

.plan_grid-item-sm {
    width: 100%;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

/* .plan__card {
    width: calc((100% / 2) - 20px);
} */

/* .plan_grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.card-custom-font-11 {
    font-size: 11px;
}

.card-custom-font-13 {
    font-size: 13px;
}

.plan-card {
    /* box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb),.15); */
}

.custom-font-color-66778F {
    color: #66778F
}

.cards-custom-font-11 {
    font-size: 11px;
}

.cards-custom-font-12 {
    font-size: 12px;
}

.card-custom-font-10 {
    font-size: 10px;
}

.custom-font-color-66778F {
    color: #66778F;
}