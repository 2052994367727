.contract_nav-link:hover {
    color: #212529;
    background-color: #E9ECEF;
}

.contract_nav-link {
    color: #212529;
    font-size: 10px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #212529;
    background-color: #E9ECEF;
}

.contract_nav {
    min-width: 150px;
}

.upgrade_content {
    width: calc(100% - 150px - 150px);
    max-width: 1000px;
}

.contract_main {
    scroll-behavior: smooth;
}